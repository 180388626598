import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "성매매 청소년, 처벌과 보호 사이",
  "slug": "data12",
  "date": "2022-03-28T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb12.png",
  "featuredImg": "../images/featured/featured12.png",
  "summary": "청소년 성매매 문제를 어떻게 해결해야 할지 그 대안을 모색하고자 했다.",
  "tags": ["정책", "성매매청소년", "법", "처벌과보호"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`청소년을 대상으로 한 성매매가 익명 채팅 앱을 매개로 공공연하게 이뤄지고 있는 것은 우리 사회의 비참한 현주소이다. 용돈이나 먹을 것, 잠잘 곳이 필요해서 성매매의 수렁에 발을 디딘 청소년들은 성매수자나 알선자의 강요와 협박, 폭력에 시달리며 고통을 겪는다. 적발되었을 때는 범법자로서 사실상의 처벌을 받게 된다. 이런 시스템에서 청소년 성매매 문제는 해결의 가닥을 찾지 못하고 있다. 청소년 성매매 문제를 어떻게 해결해야 할지 그 대안을 모색하고자 했다.`}</p>

    <h2>{`‘청소년 성매매'를 ‘청소년 성착취'로 바꿔야 한다는 주장에 공감하는가?`}</h2>
    <ChartBase type={'pie'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`현재 우리나라의 아동·청소년의 성 보호에 관한 법률은 청소년을 성착취 피해가가 아니라 성매매 당사자로 간주하고 있다. 성매매 청소년은 '대상 아동·청소년 이라는 용어로 규정된다. 이것은 특정 행위의 대상이 된 아동·청소년이란 뜻으로, 수사기관에서 피의자처럼 조사를 받으며 보호 처분의 대상이 된다. 보호처분에는 감호 위탁, 사회봉사 명령, 보호 관찰, 소년원 송치 등이 있는데, 수사 경 력 자료에 남아 상습성'의 증거 자료로 활용된다.`}<br parentName="p"></br>{`
`}{`이와 반대로 성매매 청소년을 피해 아동·청소년으로 볼 수 있다. 이때는 아동·청소년이 범죄 행위 등에 연루되어도 일반 범죄자로 간주하지 않고 해당 행위의 피해자로서 법률적 지위를 갖는다. 보호 처분이 아니라 국가로부터 상담이나 치료, 보호 등의 지원을 받을 수 있다.   `}</p>
    <h3>{`청소년 성착취의 온상인 채팅 앱을 규제해야 하는가?`}</h3>
    <p>{`2016년 국가인권위원회 자료에 따르면, 청소년 성매매에 가장 많이 이용된 경로는 채팅앱이었다. 그 비중이 67%로 가장 높았다. 같은 해 여성가족부가 발표한 자료를 봐도 청소년들의 조건 만남 경로는 채팅 앱과 랜덤 채팅 앱이 각각 37 4%. 23.4%로 절반 이상이었다. 성매매 강요 알선 범죄의 70~80%가 채팅·앱 메신저 등에서 벌어진다. 그런데 성매매 조장·방조 앱 317개 중 278개(87.7%)는 본인 인 증 없이도 이용할 수 있다.`}<br parentName="p"></br>{`
`}{`<채팅 앱에 대한 규제를 강화해야 하는가?>에 대한 질문에서는 75.7%가 매우 공감, 14.1%가 공감으로 답했으며, 5.5% 별로 공감 안함, 2.7% 전혀 공감 안함, 2.0%가 잘 모름으로 답했다.`}</p>
    <h3>{`청소년 성착취 근절을 위해 정부가 해야 할 일은?`}</h3>
    <p>{`국민 대다수는 청소년 성매매에 대한 단속 강화를 주문하고 있다. 그리고 현 상황에서 정부가 가장 중점을 둬야 할 규제로 채팅 앱 운영자 처벌 (45.3%)을 꼽았다. 다음으로 성 구매자 처벌(32%), 성매매한 청소년 처벌 (15.6%)을 들었다. 성매수자 처벌보다는 성매매 통로 차단이 우선이라고 판단하고 있다.`}<br parentName="p"></br>{`
`}{`엄중한 처벌 같은 사후 처방보다는 성매매 통로 차단 등 예방에 초점을 맞추는 의견이 높은 것으로 파악됐다. 청소년 성매매 해결을 위한 지원 방안과 관련해서는 청소년 대상 성범죄 예방 제도 강화, 가출 등 위기 청소년 지원 강화의 응답 률이 높았던 반면 성매매 피해 청소년 지원 강화라는 응답은 소수였다.`}</p>
    <h6>{`*<세계일보>와 공공의창이 함께 기획했으며 수행은 여론조사기관 서던포스트가 맡았다. 2018년 11월 26일과 27일 전국 성인남녀 1,000명을 대상으로 여론조사를 진행했으며 95% 신뢰수준에 최대 허용오차는 ±3.10%p다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      